import posthog from 'posthog-js';
import { POSTHOG_API_KEY } from './constants';

export function posthogTracking(props: {
  email: string;
  event: string;
  is_new_user?: boolean;
}) {
  if (POSTHOG_API_KEY) {
    posthog.identify(props.email, {
      event: props.event,
    });
    posthog.capture(props.event, {
      email: props.email,
      is_new_user: props.is_new_user,
    });
  }
}

export function posthogPageView(props: { fullPath: string }) {
  if (POSTHOG_API_KEY) {
    posthog.capture('$pageview', {
      $current_url: props.fullPath,
    });
  }
}
