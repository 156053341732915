import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import * as Sentry from '@sentry/vue';
import posthog from 'posthog-js';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import './config/env.config';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind.css';

import posthogLib from '@/lib/posthog.lib';

(window as any).APP_VERSION = process.env.APP_VERSION;

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App)
  .use(IonicVue, { swipeBackEnabled: false })
  .use(pinia)
  .use(router);

if (process.env.VUE_APP_POSTHOG_API_KEY) {
  app.use(posthogLib);
}

if (process.env.VUE_APP_SENTRY_DSN) {
  const integrations: any = [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'http://localhost:8080/', /^\//],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ];

  if (
    process.env.VUE_APP_POSTHOG_API_KEY &&
    process.env.VUE_APP_SENTRY_ORGANISATION_ID &&
    process.env.VUE_APP_SENTRY_PROJECT_ID
  ) {
    integrations.push(
      new posthog.SentryIntegration(
        posthog,
        process.env.VUE_APP_SENTRY_ORGANISATION_ID,
        parseInt(process.env.VUE_APP_SENTRY_PROJECT_ID)
      )
    );
  }

  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: process.env.APP_VERSION,
    integrations,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0, // TODO: change to 0.1 after dev
    replaysOnErrorSampleRate: 1.0,
  });
}

router.isReady().then(() => {
  app.mount('#app');
});
