import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { supabase } from '@/lib';
import * as Sentry from '@sentry/vue';
import { posthogPageView } from '@/utils';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/',
    component: () => import('@/views/main/TabsPage.vue'),
    children: [
      {
        path: '',
        redirect: '/home',
      },
      {
        path: 'home',
        component: () => import('@/views/main/Home.vue'),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/main/Profile.vue'),
      },
      {
        path: 'guidance',
        name: 'Guidance',
        component: () => import('@/views/main/Guidance.vue'),
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('@/views/main/About.vue'),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('@/views/auth/LoginPage.vue'),
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('@/views/auth/SignUp.vue'),
  },
  {
    path: '/confirmation-code/:email',
    name: 'ConfirmationCode',
    component: () => import('@/views/auth/VerifyAccount.vue'),
  },
  {
    path: '/confirmation-code-syndi/:email',
    name: 'ConfirmationCodeSyndi',
    component: () => import('@/views/auth/VerifyAccountSyndi.vue'),
  },
  {
    name: 'Onboarding',
    path: '/onboarding',
    component: () => import('@/views/onboarding/Onboarding.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/auth/ResetPassword.vue'),
  },
  {
    path: '/reset-password-code/:email',
    name: 'ResetPasswordCode',
    component: () => import('@/views/auth/ResetPasswordVerification.vue'),
  },
  {
    path: '/create-password',
    name: 'CreateNewPassword',
    component: () => import('@/views/auth/CreateNewPassword.vue'),
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('@/views/auth/ChangePassword.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/main/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/fitbit-connection',
    name: 'FitbitConnection',
    component: () => import('@/views/onboarding/FitbitConnection.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/integration-successful',
    name: 'IntegrationSuccessful',
    component: () => import('@/views/onboarding/IntegrationSuccessful.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/integration-failed',
    name: 'IntegrationFailed',
    component: () => import('@/views/onboarding/IntegrationFailed.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/integrations',
    name: 'Integrations',
    component: () => import('@/views/main/Integrations.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/trends',
    name: 'Trends',
    component: () => import('@/views/main/Trends.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _, next) => {
  const { data: response } = await supabase.auth.getSession();
  const isAuthenticated = !!response.session?.access_token;
  const access_token = response.session?.access_token as string;
  const refresh_token = response.session?.refresh_token as string;

  await supabase.auth.setSession({
    access_token,
    refresh_token,
  });

  posthogPageView({ fullPath: to.fullPath });

  if (to.meta.requiresAuth && isAuthenticated == false) {
    next('/login');
  } else {
    if (process.env.VUE_APP_SENTRY_DSN && response?.session?.user?.email) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          email: response.session.user.email,
        });
      });
    }

    next();
  }
});

export default router;
