import type { App } from 'vue';
import posthog from 'posthog-js';

export default {
  install(app: App) {
    app.config.globalProperties.$posthog = posthog.init(
      process.env.VUE_APP_POSTHOG_API_KEY as string,
      {
        api_host: process.env.VUE_APP_POSTHOG_API_HOST as string,
        enable_recording_console_log: true,
      }
    );
  },
};
