import { CoreScore } from '@/types';
import { format, isToday, isYesterday } from 'date-fns';

export function calculateAverageCoreScore(data: CoreScore[]): number {
  let total = 0;
  for (const value of data) {
    total += value.corescore_value;
  }
  const average = total / data.length;

  return Math.round(average);
}

export function getDateLastCoreScore(
  data: {
    core_score: number | null;
    timestamp: string;
  }[]
) {
  const timestamp = data?.[0]?.timestamp;
  if (!timestamp) {
    return '';
  }

  const date = new Date(timestamp);

  let formattedDate = format(date, 'HH:mm'); // Format time in 24-hour format

  if (isToday(date)) {
    formattedDate = 'Today at ' + formattedDate;
  } else if (isYesterday(date)) {
    formattedDate = 'Yesterday at' + formattedDate;
  } else {
    formattedDate = format(date, 'dd/MM/yyyy HH:mm'); // If not today or yesterday, show full date and time
  }

  return formattedDate;
}
