import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';

export const env = createEnv({
  clientPrefix: 'VUE_APP_',
  client: {
    VUE_APP_SUPABASE_ANON_KEY: z.string().min(1),
    VUE_APP_SUPABASE_URL: z.string().min(1),
    VUE_APP_SYNDI_ENDPOINT: z.string().min(1),
    VUE_APP_SYNDI_ENDPOINT_PROD: z.string().min(1),
    VUE_APP_SYNDI_REFERRAL_CODE: z.string().min(1),
    VUE_APP_FCLABS_CONTACT_EMAIL: z.string().min(1),
    VUE_APP_FITBIT_ENDPOINT: z.string().min(1),
    VUE_APP_GOOGLE_FORM_URL: z.string().optional(),
  },
  runtimeEnv: process.env,
});
